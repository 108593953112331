import {firstview} from './index';
import {common} from './common';
import {vues} from './vue';


document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	
	vues();
	firstview();
	common(controller , scene);
});